
function App() {
    return <>
        <div className="container">
            <div className="row">
                <div className="col-12 d-flex justify-content-center my-5">
                    <img className="img-fluid" src="imagens/logo_sol_horizontal_preto.svg" alt="" style={{maxWidth: '300px'}}/>
                </div>
            </div>
        </div>

        <div className="container-fluid d-none d-md-block">
            <div className="row px-5">
                <div className="col-3 skewx-10">
                    <a href="https://solparagliders.com.br"><img className="img-fluid rounded shadow-sm brilho" src="imagens/paraglider_desktop.jpg" alt=""/></a>
                </div>
                <div className="col-3 skewx-10">
                    <a href="https://solparamotors.com.br"><img className="img-fluid rounded shadow-sm brilho" src="imagens/paramotors_desktop.jpg" alt=""/></a>
                </div>
                <div className="col-3 skewx-10">
                    <a href="https://solsportswear.com.br"><img className="img-fluid rounded shadow-sm brilho" src="imagens/sports_desktop.jpg" alt=""/></a>
                </div>
                <div className="col-3 skewx-10">
                    <a href="https://soldefense.com.br"><img className="img-fluid rounded shadow-sm brilho" src="imagens/defense_desktop.jpg" alt=""/></a>
                </div>
            </div>
        </div>

        <div className="container-fluid d-block d-sm-block d-md-none mb-5">
            <div className="row px-3">
                <div className="col-12 mb-3 skewy-10 d-flex justify-content-center">
                    <a href="https://solparagliders.com.br"><img className="img-fluid rounded shadow-sm brilho" src="imagens/paragliders_mobile.jpg" alt=""/></a>
                </div>
                <div className="col-12 mb-3 skewy-10 d-flex justify-content-center">
                    <a href="https://solparamotors.com.br"><img className="img-fluid rounded shadow-sm brilho" src="imagens/paramotors_mobile.jpg" alt=""/></a>
                </div>
                <div className="col-12 mb-3 skewy-10 d-flex justify-content-center">
                    <a href="https://solsportswear.com.br"><img className="img-fluid rounded shadow-sm brilho" src="imagens/sports_mobile.jpg" alt=""/></a>
                </div>
                <div className="col-12 mb-3 skewy-10 d-flex justify-content-center">
                    <a href="https://soldefense.com.br"><img className="img-fluid rounded shadow-sm brilho" src="imagens/defense_mobile.jpg" alt=""/></a>
                </div>
            </div>
        </div>

        <div className="container-fluid text-center my-5">
            <p><strong>SOL SPORTS IND E COM LTDA</strong><br/>Copyright © 2014. All Rights Reserved.</p>
        </div>
    </>
}

export default App;